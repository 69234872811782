<template>
  <PageHeader></PageHeader>
 <div class="container mx-auto p-8 space-y-6">

    <!-- Title -->
    <h1 class="text-4xl font-semibold text-blue-700">Intro</h1>

    <!-- Intro Text -->
    <div class="space-y-4 text-gray-800">
        <p>I've developed a growing fascination with <strong>Artificial General Intelligence (AGI)</strong>.</p>
        
        <p>During my teenage years, I'd often find myself enthralled by the idea of computers spearheading scientific research. Just imagining a computer actively participating in the quest to cure cancer was, and still is, mind-blowing.</p>
        
        <p>Back then, AI seemed to lean more towards the 'Artificial' than the 'Intelligence'. The achievements were sparse and the dream of advanced AI felt distant, if not unreachable. Yet, the landscape has transformed remarkably since.</p>
        
        <p>Today, innovations like <strong>Alpha Fold</strong> possess the ability to predict protein structures more accurately than any human scientist. The potential implications of such advancements might reshape the world of biology as we know it.</p>
        
        <p>The breakthroughs we're witnessing, powered by tools like deep learning, especially in fields like computer vision, have been staggering. It's a thrilling era to be part of, observing the leaps and bounds in technology.</p>
        
        <p>There's a popular notion that AGI could be the final significant problem humans need to address. A thought that's both awe-inspiring and daunting.</p>
        
        <p>Regardless, I'm here, ready for whatever comes next. This website serves as my anchor in this exhilarating voyage.</p>
    </div>

    <!-- Quick Links -->
    <div class="pt-4 border-t border-gray-200">
        <h2 class="text-2xl font-medium text-gray-700 mb-4">Start Exploring</h2>
        <ul class="space-y-2">
            <li><a href="/blog/What-is-AGI" class="text-blue-500 hover:underline">What is AGI?</a></li>
        </ul>
    </div>

</div>

</template>

<script>
  import PageHeader from './PageHeader.vue';
export default {
    name: 'HomePage',
      components: {
    PageHeader
  }
}
</script>

<style scoped>
</style>
