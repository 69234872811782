
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import BlogListing from '@/components/BlogListing.vue';
import BlogPost from '@/components/BlogPost.vue';

const routes = [
    { path: '/', component: HomePage },
    { path: '/blog', component: BlogListing },
    {
        path: '/blog/:id',
        name: 'BlogPost',
        component: BlogPost,
	props: true
    }
  // You can add routes for Blog and Resources when you create those components
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
