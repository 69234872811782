<template>
  <nav class="bg-blue-500 text-white p-4">
    <div class="container mx-auto flex items-center justify-between">
      <!-- Logo and Title -->
      <div class="flex items-center space-x-3">
        <!-- Wrap the image in a router-link -->
        <router-link to="/">
          <img class="h-10 w-10 p-1 bg-white rounded-full" src="/icon.png" alt="Your Company">
        </router-link>
        <h1 class="text-xl font-semibold">AGI Today</h1>
      </div>

      <!-- Navigation Links -->
      <ul class="flex space-x-4 mt-2">
        <li>
          <router-link to="/" class="px-2 py-1 rounded hover:bg-blue-400">Home</router-link>
        </li>
        <li>
          <router-link to="/blog" class="px-2 py-1 rounded hover:bg-blue-400">Blog</router-link>
        </li>
        <!-- Uncomment the below line when you want to use the Resources link -->
        <!-- <li><router-link to="/resources" class="px-2 py-1 rounded hover:bg-blue-400">Resources</router-link></li> -->
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'PageHeader'
}
</script>

<style scoped>
</style>
